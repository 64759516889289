.navigation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tab-content {
    flex: 0 1 90%;
    .react-swipeable-view-container {
      height: 100%;
    }
  }
  .navigation-bar {
    // position: fixed;
    bottom: 0;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    .navigation-tab {
      width: 100%;
      height: 100%;
      margin: 0 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.1);
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }

      &.active-tab {
        box-shadow: inset 0 0.8vh 0px 0px rgba(255, 255, 255, 0.2);
      }

      .tab-icon {
        font-size: 125%;
      }
      .tab-text {
        font-size: 50%;
        font-weight: 100;
      }
    }
  }
}