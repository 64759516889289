.header {
  position: fixed;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 15%;

  .header-logo {
    display: flex;
    align-items: center;
    margin: 0 40px;
    img {
      width: 50px;
      height: 50px;
    }
  }

  .header-icon-more {
    text-align: center;
    width: 1em;
    margin: 0 40px;
    font-size: 150%;
    cursor: pointer;
  }
  
  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.5em 1em;
    border: "none";
    box-shadow: "0px 0px 50px rgba(0,0,0,0.2)";

    >* {
      margin: 0.5em 0;
      color: $color-main;
      text-decoration: none;
      font-size: 50%;
      cursor: pointer;
    }
  }
}