.timer-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .timer-digits-container {
    flex: 0 0 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 100;

    .timer-digits {
      .timer-title {
        font-size: 40%;
        text-align: center;
      }
      .digits {
        display: block;
        width: 2.5em;
        padding: 0;
        margin: 0;
        line-height: 1;
        background: none;
        border: none;
        font: inherit;
        color: inherit;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        transition: 0.2s;

        &.digits-show {
          line-height: 1.15;
        }
      }
      .hide-digits {
        display: none;
      }
    }

    .main-timer {
      font-size: 400%;
    }
    .prepare-timer {
      font-size: 150%;
    }
  }

  .timer-controls {
    flex: 0 1 25%;
    .timer-controls-icon {
      font-size: 300%;
      transition: 0.2s;
      cursor: pointer;
      transform: scale(1) translateX(0);
      &:nth-child(2) {
        transform: scale(1) translateX(50%);
      }
      &:nth-child(3) {
        transform: scale(1) translateX(-50%);
      }
      &.additional-icon {
        transform: scale(0.6) translateX(0);
      }
      &.hide-icon {
        transform: scale(0) translateX(0);
      }
    }
  }
  .popup-content {
    border: none;
    box-shadow: 0px 0px 50px rgba(0,0,0,0.6);
    width: 50%;
    height: 50%;
    min-width: 300px;
    min-height: 200px;
    max-width: 500px;
    max-height: 300px;
    padding: 1em !important;
  
    .popup-content-wrap {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      font-size: 100%;
      text-align: center;
      color: $color-main;
      >h2 {
        font-size: 80%;
      }
      >p {
        font-size: 50%;
      }
      .icon {
        font-size: 250%;
        transition: 0.2s;
        cursor: pointer;
      }
      .control-icons {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
      }
    }
  }

  .paused-animation {
    animation: fadeInOut 1s infinite linear alternate;
  }
}

@keyframes fadeInOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}