.journal-page {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  .journal-nav {
    height: 40px;
    width: 80%;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 480px) {
      width: calc(100% - 20px);
    }

    &.center {
      justify-content: center;
    }

    i {
      font-size: 1.5em;

      &:hover {
        cursor: pointer;
      }
    }

    h1 {
      font-size: 90%;
      font-weight: 900;
    }
  }

  .card-container {
    width: 80%;
    height: 80%;
    max-width: 1000px;
    max-height: 800px;
    position: relative;

    @media screen and (max-width: 480px) {
      width: calc(100% - 20px);
      font-size: 24px;
    }

    .notes-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .note {
        width: 100%;
        height: 90px;
        display: flex;
        color: black;
        padding-top: 1em;

        .date-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;

          .date {
            height: 1.5em;
            width: 1.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ebeeee;
            border-radius: 10px;
            font-size: 70%;
            font-weight: 100;
            padding: 4px;
          }

          span {
            font-size: 55%;
            margin-top: 4px;
            font-weight: 100;
            color: #3c3c3c;
          }
        }

        .content {
          width: 100%;
          border-bottom: 1px solid #cacdcd;
          margin-left: 0.5em;
          overflow: hidden;
          padding-bottom: 1rem;
          max-height: 100%;

          span {
            height: 100%;
            display: block;
            overflow: hidden;
            font-weight: 100;
            font-size: 65%;
            color: #3c3c3c;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}
