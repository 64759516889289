.app-container {
  background-image: linear-gradient(350deg, $color-main-dark, $color-main 50%, $color-main-light);
  font-size: calc(2vh + 1vw);
  color: $color-second;
  stroke: $color-second;
  fill: $color-second;
  stop-color: $color-second;

  @media screen and (max-width: 680px) {
    font-size: calc(3vh + 1vw); 
  }
}

.page {
  position: absolute;
  width: 100%;
  height: 100%;
}

.content-page {
  height: calc(100% - 120px - 50px);
  margin: 120px 0 50px 0;
  overflow: auto;

  @media only screen and (max-width: 600px) {
    height: calc(100% - 90px - 30px);
    margin: 90px 0 30px 0;
  }
  h1 {
    font-size: 150%;
    font-weight: 100;
  }
  h3 {
    font-size: 70%;
    font-weight: 900;
    margin: 2em 0 0 0;
  }
  p, ol {
    font-size: 60%;
    line-height: 1.2;
    margin-left: 20px;
    font-weight: 100;
    margin: 1em 0;
  }
  a {
    color: inherit;
    font-weight: 500;
  }
  ol {
    margin-left: 20px;
  }
  .post {
    max-width: 1080px;
    width: 80%;
    margin: auto;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none !important;
}

.google-login-button {
  width: 200px;
  height: 50px;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./attachments/images/google_button.png');
  background-size: 100% auto;
  cursor: pointer;
}

.login-overlay {
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 5;
  &.without-bg {
    background-color: transparent;
  }
  .login-error-block {
    font-size: 40%;
    color: #cc0000;
  }
}