.chart-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .period-select-container {
    height: 30px;
    font-size: 20px;
    display: flex;
    align-items: center;

    span {
      margin: 2px;
      padding: 5px;
      cursor: pointer;
    }

    .selected {
      background-color: $color-second;
      border-radius: 20px;
      color: $color-main;
    }
  }

  .chart-container {
    width: 80%;
    height: 80%;
    max-width: 1000px;
    max-height: 800px;
    position: relative;

    @media screen and (max-width: 480px) {
      width: 100%;
      height: calc(100% - 30px);
    }

    .chart-block {
      position: relative;
      width: 100%;
      height: 100%;
      font-size: 60%;

      text {
        fill: $color-second;
        font-size: 120%;
        font-style: italic;
        &.label {
          font-style: normal;
          font-size: 180%;
        }
      }

      svg, g, path {
        // fill: white;
        stroke: white;
      }
    }
  }
}