$date-picker-color: #0000ee;
$note-font-color: #3c3c3c;
$note-font-size: 70%;
$note-font-weight: 100;
$note-line-height: 1.4em;

.card {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;

  .card-header {
    background-color: #ebeeee;
    min-height: 3em;
    display: flex;
    align-items: center;
    padding: 5px 0 5px 0.7em;
    font-weight: 400;
    font-size: 65%;
    color: black;

    .date-picker-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        margin-right: 8px;
        color: $date-picker-color;
      }

      .date-picker {
        width: 150px;
        margin-bottom: 4px;
      }

      input[class^="MuiInputBase-input-"], input[class^="jss"] {
        color: $date-picker-color;
        padding-bottom: 0;
        &:hover {
          cursor: pointer;
        }
      }

      div[class*="MuiInput-underline-"], div[class*="jss"] {
        &:before, &:after {
          display: none;
        }
      }
    }
  }

  .no-scroll {
    overflow: hidden !important;
  }

  .card-body {
    height: calc(100% - 10px - 3.2em);
    color: black;
    overflow-y: scroll;
    padding: 0.6em;

    .selected-note {
      color: $note-font-color;
      font-size: $note-font-size;
      font-weight: $note-font-weight;
      line-height: $note-line-height;
    }

    .edit-note {
      height: 100%;
      width: 100%;
      textarea {
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        border: 0;
        resize: none;
        outline: none;
        color: $note-font-color;
        font-size: $note-font-size;
        font-weight: $note-font-weight;
        line-height: $note-line-height;
      }
    }
  }


  .add-journal-btn {
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 70px;
    height: 70px;
    border-radius: 100px;
    background-color: #0054aa;
    outline: none;
    border: none;

    &:hover {
      cursor: pointer;
      background-color: #0065eb;
    }

    i {
      color: white;
      font-size: 40px;
    }
  }
}
